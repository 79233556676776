<template>
	<div class="page">
		<div class="view">
			<div class="viewT">
				<div style="font-weight: bold;">店员信息</div>
				<el-button style="width: 110px;" @click="handleBackClick">返回</el-button>
			</div>
			<div class="viewC">
				<div class="comView">
					<div class="comItem" style="align-items: initial;height: auto;">
						<div class="comItemName">店员头像:</div>
						<el-upload ref="fileUpload" :http-request="getUploadImg" :multiple="false" list-type="picture-card"
							:auto-upload="true" :show-file-list="false" action="" class="cropper-upload-box">
							<div style="display: flex;flex-direction: column;align-items: center;justify-content: center">
								<img v-if="imgUrl1" :src="imgUrl1" class="avatar">
								<!-- <i v-else class="el-icon-plus el-icon"></i> -->
							</div>
						</el-upload>
					</div>
				</div>
				<div class="comView">
					<div class="comItem">
						<div class="comItemName">店员姓名:</div>
						<el-input v-model="realName" placeholder="请输入店员姓名" style="width: 200px;"></el-input>
					</div>
				</div>
				<div class="comView">
					<div class="comItem">
						<div class="comItemName">店员昵称:</div>
						<el-input v-model="nickName" placeholder="请输入昵称" style="width: 200px;"></el-input>
					</div>
				</div>
				<div class="comView">
					<div class="comItem">
						<div class="comItemName">地址:</div>
						<el-input v-model="userAddress" placeholder="请输入昵称" style="width: 200px;"></el-input>
					</div>
				</div>
				<div class="comView">
					<div class="comItem">
						<div class="comItemName">性别:</div>
						<el-radio-group v-model="sex">
							<el-radio label="男">男</el-radio>
							<el-radio label="女">女</el-radio>
						</el-radio-group>
					</div>
				</div>
				<div class="comView">
					<div class="comItem">
						<div class="comItemName">账号:</div>
						<el-input v-model="userName" placeholder="请输入昵称" style="width: 200px;"></el-input>
					</div>
				</div>
				<div class="comView">
					<div class="comItem">
						<div class="comItemName">手机号:</div>
						<el-input v-model="userMobile" placeholder="请输入手机号" style="width: 200px;"></el-input>
					</div>
				</div>
				<div class="comView">
					<div class="comItem">
						<div class="comItemName">登录密码:</div>
						<el-input type="password" v-model="userPwd" placeholder="请输入登录密码" style="width: 200px;"></el-input>
					</div>
				</div>
				<div class="comView">
					<div class="comItem">
						<div class="comItemName">店员职位:</div>
						<el-select v-model="rouleId" placeholder="请选择" style="width: 200px;">
							<el-option v-for="item in rouleList" :key="item.roule.code" :label="item.roule.name" :value="item.roule.code">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="comView">
					<div class="comItem">
						<div class="comItemName">预约状态:</div>
						<el-radio-group v-model="isMake">
							<el-radio :label="0">是</el-radio>
							<el-radio :label="1">否</el-radio>
						</el-radio-group>
					</div>
				</div>
				<div class="comView" v-if="routeData != null">
					<div class="comItem">
						<div class="comItemName">店员状态:</div>
						<el-radio-group v-model="isLeave">
							<el-radio :label="0">正常</el-radio>
							<el-radio :label="1">离职</el-radio>
						</el-radio-group>
					</div>
				</div>
			</div>
			<div class="viewB">
				<el-button type="primary" style="width: 110px;margin-right: 20px;" @click="handleSubmitClick">提交
				</el-button>
			</div>
		</div>
	</div>
</template>

<script>
import { put } from '@/api/upload.js'
export default {
	data() {
		return {
			imgUrl1: '',
			avatarPic: '', //头像
			realName: '',  //姓名
			userMobile: '',  //手机号
			userPwd: '',  //密码
			isMake: 0,  //预约状态
			isLeave: 0,  //状态
			rouleList: [],  //职位列表
			rouleId: '', //职位
			routeData: null,
			sex:'',
			nickName:'',
			userName:'',
			userAddress:''
		}
	},
	mounted() {
		this.getRouleData()
		if (this.$route.query.routeData) {
			this.routeData = JSON.parse(this.$route.query.routeData)
			this.imgUrl1 = this.routeData.basicInfo.avatarPic;
			this.avatarPic = this.routeData.basicInfo.avatarPic;
			this.realName = this.routeData.basicInfo.realName;
			this.userMobile = this.routeData.basicInfo.userMobile;
			this.sex = this.routeData.basicInfo.sex;
			this.nickName = this.routeData.basicInfo.nickName;
			this.userName = this.routeData.basicInfo.userName;
			this.userAddress=this.routeData.basicInfo.userAddress;
			this.rouleId = this.routeData.roleInfo.projectCode;
			if (this.routeData.basicInfo.isMake == true) {
				this.isMake = 0;
			} else {
				this.isMake = 1;
			}
			if (this.routeData.basicInfo.isLeave == true) {
				this.isLeave = 1;
			} else {
				this.isLeave = 0;
			}
		}
	},
	methods: {
		//提交
		handleSubmitClick() {
			if (this.realName == '') {
				this.$message({
					type: 'error',
					message: '请输入店员姓名'
				});
				return
			}
			if (this.userMobile == '') {
				this.$message({
					type: 'error',
					message: '请输入手机号'
				});
				return
			}
			if (this.rouleId == '') {
				this.$message({
					type: 'error',
					message: '请选择店员职位'
				});
				return
			}
			let params = {
				basicInfo: {
					userPwd: this.userPwd,
					userMobile: this.userMobile,
					realName: this.realName,
					avatarPic: this.avatarPic,
					sex:this.sex,
					nickName:this.nickName,
					userName:this.userName,
					userAddress:this.userAddress

				},
				roleInfo: {}
			}
			if (this.isMake == 0) {
				params.basicInfo.isMake = true;
			} else {
				params.basicInfo.isMake = false;
			}
			this.rouleList.map((item) => {
				if (this.rouleId == item.roule.code) {
					params.roleInfo = {
						projectId: item.roule.id,
						projectName: item.roule.name,
						projectCode: item.roule.code
					}
				}
			})
			if (this.isLeave == 0) {
				params.basicInfo.isLeave = false
			} else {
				params.basicInfo.isLeave = true
			}
			if (this.routeData == null) {  //新增
				this.$http.post("/cms/StaffInfo/AddStaffInfo", params).then(res => {
					if (res.code === 0) {
						this.$router.go(-1);
						this.$message({
							type: 'success',
							message: '已新增'
						});
					}
				})
			} else {   //编辑
				params.id = this.routeData.id;
				this.$http.post("/cms/StaffInfo/UpdateStaffInfo", params).then(res => {
					if (res.code === 0) {
						this.$router.go(-1);
						this.$message({
							type: 'success',
							message: '已修改'
						});
					}
				})
			}

		},
		//获取职位列表
		getRouleData() {
			let params = {
				pageIndex: 0,
				pageSize: 0,
			}
			this.$http.post("/cms/System/GetRouleList", params).then(res => {
				if (res.code === 0) {
					this.rouleList = res.data.list;
				}
			})
		},
		getUploadImg(item) {
			// 随机命名
			var fileName = item.file.name
			var file = item.file // 
			put(fileName, file).then(result => {  // 调oss api 上传图片
				this.avatarPic = result.url;
				this.imgUrl1 = result.url;
				// this.fileList.push(result.name)
			})
		},
		//返回
		handleBackClick() {
			this.$confirm('是否返回上一页?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$router.go(-1);
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消'
				});
			});
		},
	}
}
</script>

<style lang="scss" scoped>
.page {
	width: 100%;
	height: 100%;
	color: $fontColor;

	.view {
		background: #fff;
		height: 100%;

		border-radius: 20px;
		display: flex;
		flex-direction: column;

		.viewT {
			box-sizing: border-box;
			height: 68px;
			display: flex;
			align-items: center;
			border-bottom: solid 1px #F2F2F2;
			padding: 0 20px;
			justify-content: space-between;
		}

		.viewC {
			flex: 1;
			overflow-y: scroll;
			padding-top: 20px;

			.comView {
				display: flex;
				align-items: center;
				margin-bottom: 20px;
				font-size: 14px;

				.comItem {
					display: flex;
					align-items: center;
					height: 40px;

					.comItemName {
						width: 80px;
						text-align: right;
						margin-right: 8px;

						span {
							color: #FF3939;
						}
					}
				}
			}
		}

		.viewB {
			height: 68px;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			box-sizing: border-box;
			border-top: solid 1px #F2F2F2;
		}

		.viewC::-webkit-scrollbar {
			display: none;
		}
	}

	.upload-cropper-wrap {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.cropper-upload-box {
		display: flex;

		.el-upload {
			width: 120px;
			height: 120px;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
			width: 120px;
			height: 120px;
		}
		}
		.el-icon{
			text-align: center;
			line-height: 50%;
		}
	}
}
</style>
